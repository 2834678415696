<template>
  <div>
    <loader fullScreen ref="crcaLoader" />

    <iframe
      v-if="reportEmbedReady"
      :src="embedUrl"
      v-bind:style="iframeStyle"
      :height="iframeHeight"
    ></iframe>
    <p v-else>No report selected.</p>
  </div>
</template>

<script>
  export default {
    name: 'TableauReport',
    props: {
      report: {
        default: {}
      },
      heightOffset: {
        type: Number,
        default: 75
      }
    },
    data: function () {
      return {
        embedUrl: false,
        iframeStyle: {
          width: '100%',
          border: '1px solid #383F45'
        },
        iframeHeight: null
      };
    },
    computed: {
      reportEmbedReady() {
        return this.report;
      }
    },
    methods: {
      setIframeHeight() {
        // less BS way of using this by watching the window changes, so it can change when the window does
        const bodyRect = document.body.getBoundingClientRect();
        const offsetTop = bodyRect.top;

        this.iframeHeight = window.innerHeight - offsetTop - this.heightOffset;
      }
    },
    watch: {
      report: {
        deep: true,
        handler: function (newVal, oldVal) {
          this.$refs.crcaLoader.openLoader();

          this.$http({
            url: '/tableau-access'
          })
            .then((response) => {
              this.embedUrl =
                response.data.data +
                this.report.url +
                '?:embed=y&:showAppBanner=false&:toolbar=no&:display_count=n&:showVizHome=n&:origin=viz_share_link';
            })
            .finally(() => {
              this.$refs.crcaLoader.closeLoader();
            });
        }
      }
    },
    mounted() {
      this.setIframeHeight();
    },
    created() {
      // iframe height magic happens here.
      window.addEventListener('resize', this.setIframeHeight);
    }
  };
</script>
