<template>
  <div class="form-group">
    <v-select
      :class="{ 'has-selection': value }"
      v-model="inputVal"
      :label="optionLabel"
      :options="options"
      :reduce="reduce"
      v-bind="$attrs"
      :disabled="disabled"
    >
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </v-select>
    <label class="control-label" v-html="fieldLabel"></label><i class="bar"></i>
  </div>
</template>

<script>
  import vSelect from 'vue-select'; // https://vue-select.org/

  export default {
    name: 'SingleSelect',
    props: ['value', 'optionLabel', 'options', 'fieldLabel', 'reduce', 'disabled'],
    components: {
      vSelect
    },
    computed: {
      inputVal: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  };
</script>
