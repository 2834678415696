<!-- eslint-disable vue/valid-template-root -->
<template>
  <div>
    <div class="rfs-m-8-b">
      <single-select
        v-model="selectedReport"
        :options="reports"
        fieldLabel="Select Report"
        optionLabel="name"
      ></single-select>
    </div>
    <TableauReport :report="selectedReport" :heightOffset="200">
    </TableauReport>
  </div>
</template>

<script>
  import TableauReport from '@/components/externalreports/TableauReport.vue';
  import SingleSelect from '@/components/base/forms/single_select.vue';

  export default {
    name: 'VarianceReportsExternalReports',
    components: {
      TableauReport,
      SingleSelect
    },
    data() {
      return {
        selectedReport: false,
        loadedReport: false
      };
    },
    computed: {
      reports() {
        return this.$storage.get('external_reports');
      }
    }
  };
</script>
